import React, { useEffect } from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FormattedMessage } from "gatsby-plugin-react-intl";


import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import OfferHero from "../components/offer/hero";
import OfferBenefits from "../components/offer/benefits";
import OfferForm from "../components/offer/form";
import FeaturedIn from "../components/offer/featuredIn";
import Customers from "../components/offer/customers";

const Offer = ({ location }) => {

    const params = new URLSearchParams(location.search);
    const scrollToForm = params.get("scroll-to-form");

    // Called on mount
    useEffect(() => {
        if (scrollToForm) {
            scrollTo('#offer-form-anchor')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Layout isLight={true}>
            <Seo
                title="Offer"
                description="Electricity that makes a difference – for your business and the climate"
            />
            <OfferHero></OfferHero>
            <FeaturedIn bgClasses="bg-[#F3F3F3]"></FeaturedIn>
            <OfferBenefits></OfferBenefits>
            <div className="w-screen bg-[#F3F3F3] py-16 lg:py-28 flex flex-col items-center">
                <div className="w-full md:max-w-[1220px] flex flex-col items-center px-4">
                    <h2 className="text-xl md:text-3xl font-medium py-1 lg:max-w-4xl"> <FormattedMessage id="offer_form_heading" /> </h2>
                </div>
                <div className="w-full md:max-w-[1220px]">
                    <OfferForm formID="offer-hero-offer-form"></OfferForm>
                </div>
            </div>
            <Customers></Customers>
        </Layout >
    );
};
export default Offer;
